import * as React from "react";

// React
import { useState } from "react";
import toast from "react-hot-toast";
import Seo from "../components/seo";

// Images
import HomeImage from "../images/home/Home.png";
import WorkQueue from "../images/home/WorkQueueExample.png";
import MobileExample from "../images/features/testing-utility/header.png";
import chartsPerson from "../images/home/charts-person.png";

// SVGs
import ShieldBackdrop from "../images/home/shield-backdrop.inline.svg";
import AppleLogo from "../images/home/apple.inline.svg";
import PlayLogo from "../images/home/google-play.inline.svg";
import GraphBackdrop from "../images/home/graph-backdrop.inline.svg";

// Lotties
import RealTimeTestingLottie from "../images/home/lotties/RealTimeTestingLottie";

// Heroicons
import { BadgeCheckIcon } from "@heroicons/react/solid";

// Shared Components
import LoadingButton from "../components/shared/loading-button/LoadingButton";
import CustomToast from "../components/shared/toast/CustomToast";
import Footer from "../components/shared/footer/Footer";
import Header from "../components/shared/header/Header";

// Service
import { contactSubmission } from "../services/contactAPI";

// Hooks
import { Link } from "gatsby";

export default function Index({ location, data }) {
  const [loading, setLoading] = useState(false);
  const [interestFormSent, setinterestFormSent] = useState(false)

  const handleSubmit = async (event) => {
    //Preventing page from reloading
    event.preventDefault();
    setLoading(true);

    let email = event.target.email.value;

    let postBody = JSON.stringify({
      email: email,
      PageName: "Home",
    });

    contactSubmission(postBody).then(async (response) => {
      const data = await response;
      if (data.dbSuccess === true && data.endUserEmailSuccess === true) {
        setinterestFormSent(true)
        toast.custom(
          (t) => (
            <CustomToast
              toast={t}
              toastType={"success"}
              title={"Success!"}
              message={
                "Thank you for your interest in Tech Tester! Please check your inbox for updates."
              }
            />
          ),
          { duration: 3000 }
        );
        setLoading(false);
        event.target.reset();
      } else {
        toast.custom(
          (t) => (
            <CustomToast
              toast={t}
              toastType={"failure"}
              title={"Error"}
              message={"Something went wrong. Please try again."}
            />
          ),
          { duration: 3000 }
        );
        setLoading(false);
        event.target.reset();
      }
    });

    //temporarily commented out code is API call to sign up

    // await post('signup', postBody).then(async response => {
    //     const data = await response;
    //     console.log('data returned to request: ' + JSON.stringify(data));
    //     if (data){
    //         if (data.message === "Please check your email to complete account registration.") {
    //             navigate('/email-sent-confirmation', { state: {email:email }});
    //         } else {
    //             toast.custom((t) =>
    //                 (<CustomToast toast={t} toastType={'failure'} title={'Error'} message={"Account could not be created. Please try again."}/>), {duration: 3000}
    //             )
    //         }
    //     }
    // }).catch(async error => {
    //     const isConfirmed = await confirm("This email address is already in use. Would you like to resend the confirmation email?")
    //     if (isConfirmed) {
    //         // RESEND EMAIL
    //         await post(`signup/resend`, JSON.stringify({'email': email})).then((data) => {
    //             console.log('data returned to request: ' + JSON.stringify(data));
    //             if (data.message === "Please check your email to complete account registration.") {
    //                 navigate('/email-sent-confirmation', { state: {email:email }});
    //             } else {
    //                 toast.custom((t) =>
    //                     (<CustomToast toast={t} toastType={'failure'} title={'Error'} message={data.message}/>), {duration: 3000}
    //                 )
    //                 navigate('/signup');
    //             }
    //         })
    //         console.error('Error returned from API!', error);
    //     }
    // });
  };

  return (
    <div>
      <Seo title="Tech Tester" />
      <Header />
      {/* Hero section */}
      <div className="relative bg-navy">
        <ShieldBackdrop
          className={"object-scale-down absolute top-0 right-0 h-100 w-auto"}
        />
        <div
          className={"absolute inset-x-0 bottom-0 flex-1 h-40 bg-gray-100"}
        ></div>
        <div className="absolute inset-x-0 bottom-0 h-1/2" />

        <div className="max-w-7xl mx-auto  sm:px-6 lg:px-8 bg-navy">
          <div className="relative">
            <div className="relative px-4 sm:px-6 pt-12 md:pt-20 lg:px-8 ">
              <div className={"md:max-w-6xl"}>
                <h1 className="text-white font-bold lg:text-5xl md:mt-4 pb-8 md:pb-2 md:text-left md:text-4xl text-3xl text-center">
                  The ultimate utility for alarm companies.
                </h1>

              </div>
              <div className={"mx-auto md:max-w-4xl mt-2 md:-mt-12 mb-2 md:mb-0 -m-20 "}>
                <RealTimeTestingLottie />
              </div>
              <div className={"md:max-w-6xl"}>
                <div>
                  <p className={"text-white mt-4 max-w-5xl text-lg mx-6 md:mx-2"}> <strong>Tech Tester</strong> is a mobile and web-based utility for alarm companies to integrate with their Central Station. Easily configure access limits, permissions, and change-logs for your team of technicians. The testing utility makes it easy and intuitive to see what sensors and points have communicated. When done testing, with a single button press, send a beautiful report of the testing details to your office or directly to the subscriber. Utilize work-queues and in-app messaging to manage your team. <strong>Tech Tester</strong> makes your day-to-day security and fire system servicing easy and secure.</p>
                </div>
              </div>
              <div className={"grid grid-cols-1 md:grid-cols-2 p-5 lg:py-20"}>
                <div className={"flex items-start items-center"}>
                  <BadgeCheckIcon
                    className={"h-10 md:h-14 w-auto text-silver-light"}
                  />
                  <p className="text-lg font-medium text-white p-5">
                    Direct Central Station Integration
                  </p>
                </div>
                <div className={"flex items-start items-center"}>
                  <BadgeCheckIcon
                    className={"h-10 md:h-14 w-auto text-silver-light"}
                  />
                  <p className="text-lg font-medium text-white p-5">
                    Real-Time Signal Testing Utility
                  </p>
                </div>
                <div className={"flex items-start items-center"}>
                  <BadgeCheckIcon
                    className={"h-10 md:h-14 w-auto text-silver-light"}
                  />
                  <p className="text-lg font-medium text-white p-5">
                    Granular Team Management
                  </p>
                </div>
                <div className={"flex items-start items-center"}>
                  <BadgeCheckIcon
                    className={"h-10 md:h-14 w-auto text-silver-light"}
                  />
                  <p className="text-lg font-medium text-white p-5">
                    Full Data Entry Capabilities
                  </p>
                </div>
                <div className={"flex items-start items-center"}>
                  <BadgeCheckIcon
                    className={"h-10 md:h-14 w-auto text-silver-light"}
                  />
                  <p className="text-lg font-medium text-white p-5">
                    Transparent Central Station Access
                  </p>
                </div>
                <div className={"flex items-start items-center"}>
                  <BadgeCheckIcon
                    className={"h-10 md:h-14 w-auto text-silver-light"}
                  />
                  <p className="text-lg font-medium text-white p-5">
                    Time-Based App Access
                  </p>
                </div>
                <div className={"flex items-start items-center"}>
                  <BadgeCheckIcon
                    className={"h-10 md:h-14 w-auto text-silver-light"}
                  />
                  <p className="text-lg font-medium text-white p-5">
                    Beautiful Branded Reports
                  </p>
                </div>
                <div className={"flex items-start items-center"}>
                  <BadgeCheckIcon
                    className={"h-10 md:h-14 w-auto text-silver-light"}
                  />
                  <p className="text-lg font-medium text-white p-5">
                    And So Much More!
                  </p>
                </div>
              </div>
            </div>

            <div className="inset-0">
              <img
                className="h-full w-full object-scale-down shadow-xl sm:rounded-lg sm:overflow-hidden"
                src={HomeImage}
                alt="Home Console User Interface"
              />
            </div>
          </div>
        </div>
      </div>
      {/* Sign Up Section */}
      <div className={"flex bg-gray-100 lg:py-10"}>
        <div className="mx-auto max-w-3xl text-center md:px-5">
          <div className="pt-5 px-5">
            <h4 className="text-2xl md:text-3xl font-medium tracking-tight text-gray-900">
              Coming soon, sign up for updates!{" "}
            </h4>
          </div>

          <form
            className="space-y-6 row-auto py-5  mx-4 md:mx-0 max-w-3xl"
            onSubmit={handleSubmit}
          >
            <div className="mx-auto">
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4 items-center justify-between">
                <div className="mt-1 md:col-span-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    placeholder="Email"
                    required
                    className="text-center md:text-left appearance-none block w-full px-5 py-3 rounded-md shadow-sm text-gray-800 placeholder-black focus:outline-none bg-myGray-400 font-sm md:font-md focus:border-0"
                  />
                </div>

                <div className="mt-1">
                  {loading ? (
                    <LoadingButton type={"email"} />
                  ) : (
                    <button className={`w-full flex justify-center px-5 py-3 border border-transparent rounded-md shadow-sm text-sm  font-bold ${interestFormSent ? "bg-greenTT text-white" : "bg-yellowTT hover:bg-yellowTT-alt text-black"}`}>
                     {interestFormSent ? "SENT" : "KEEP ME IN THE LOOP"}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      {/* Alternating Feature Sections */}
      <div className="relative pt-16 pb-32 overflow-hidden">
        <div
          aria-hidden="true"
          className="absolute inset-x-0 top-0 h-48 bg-gradient-to-b from-gray-100"
        />
        <div className="relative">
          <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
            <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
              <div>
                <div className="mt-6">
                  <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                    Access: the way you want it.
                  </h2>
                  <p className="mt-4 text-lg text-gray-500">
                    Our granular permissions allow you to give as much or as
                    limited access to your monitored accounts as you want in
                    order to fit your business needs. Assign your techs to just
                    the accounts they are servicing, nothing more. Time-based
                    app access, transparent central station logins, and detailed
                    usage statistics make securing your account base easy and
                    worry-free.
                  </p>
                  <div className="mt-6">
                    <Link
                      to="/features/granular-permissions"
                      className="inline-flex bg-gradient-to-r from-silver-light to-silver-light bg-origin-border px-4 py-2 border border-transparent text-base font-semibold rounded-md shadow-md text-black hover:from-silver-alt hover:to-silver-alt"
                    >
                      Learn More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-12 sm:mt-16 lg:mt-0">
              <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                <img
                  className="w-full lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                  src={WorkQueue}
                  alt="Work Queue User Interface"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="relative mt-24 py-24 bg-myGray-200">
          <div className="relative z-10 grid grid-cols-1 text-center place-items-center">
            <div>
              <img
                className="w-64 h-auto"
                src={chartsPerson}
                alt="Tech Tester App User Interface"
              />
            </div>

            <div className="mt-6 p-10">
              <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                Reporting
              </h2>
              <p className="mt-4 text-lg text-gray-500 max-w-5xl">
                Team management can be hard. Tech Tester makes it easy with
                beautiful automated reports. See your teams usage metrics, test
                utility reports, and data changes all right in your inbox.{" "}
              </p>
              <div className="mt-6">
                <Link
                  to="/features/work-queue"
                  className="inline-flex bg-gradient-to-r from-silver-light to-silver-light bg-origin-border px-4 py-2 border border-transparent text-base font-semibold rounded-md shadow-md text-black hover:from-silver-alt hover:to-silver-alt"
                >
                  Learn More
                </Link>
              </div>
            </div>
          </div>
          <GraphBackdrop
            className={"absolute h-80 w-80 top-40 right-40 fill-myGray-300"}
          />
        </div>
        <div className="mt-24">
          <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
            <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
              <div>
                <div className="mt-6">
                  <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                    The app you need.
                  </h2>
                  <p className="mt-4 text-lg text-gray-500">
                    Our application empowers your team by giving them the tools
                    they need to service your accounts with ease. Our testing
                    utility lets the user easily see what sensors have reported,
                    what has been restored, and what is still unrestored. They
                    can then send a report summary to your office that shows
                    that all sensors have reported and restored before they
                    leave the site.
                  </p>
                  <div className="grid grid-cols-2 mt-20 ">
                    <div className={""}>
                      <Link
                        to="/features/mobile-app"
                        className="items-center w-50 h-16 mr-5 inline-flex bg-black bg-origin-border px-6 py-4 border border-transparent text-base font-medium rounded-md shadow-sm text-white"
                      >
                        <AppleLogo className={"w-7 h-7 fill-white mr-2"} />
                        Coming Soon!
                      </Link>
                      {/*<p className={"ml-3 pt-2 text-gray-500"}>4.56 out of 1938 Ratings</p>*/}
                      {/*<img className={"ml-10 w-20 h-auto"} src={StarRating}/>*/}
                    </div>
                    <div>
                      <Link
                        to="/features/mobile-app"
                        className="items-center w-50 h-16 inline-flex bg-black bg-origin-border px-6 py-4 border border-transparent text-base font-medium rounded-md shadow-sm text-white"
                      >
                        <PlayLogo className={"w-7 h-7 fill-white mr-2"} />
                        Coming Soon!
                      </Link>
                      {/*<p className={"ml-16 pt-2 text-gray-500"}>NEW!</p>*/}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="m-auto">
              <div className="pl-4 md:-mr-16 sm:pl-6 lg:px-0 lg:m-0 lg:relative lg:h-full">
                <img className="" src={MobileExample} alt="Temp Item" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
