
import lottie from 'lottie-web';
import React, { useEffect}  from "react";
import signalsAnimation from './real-time-testing.json';

export default function RealTimeTestingLottie() {

    useEffect(() => {
        lottie.loadAnimation({
            container: document.querySelector("#lottie-one"),
            renderer: 'svg',
            loop: true,
            autoplay:true,
            animationData: signalsAnimation ,
        })

    }, []);

        return (
            <div className={'flex-1'} id="lottie-one"></div>
        );

}

